import React from 'react';


export const ru = {
    main: {
        slogan: 'ПОВЫШАЕМ ПРОИЗВОДИТЕЛЬНОСТЬ ТРУДА'
    },
    aboutSolut: {
        description: 'SOLUT – это система мониторинга производительности ручного труда, повышающая его производительность на 30% и более. Каждую смену и для каждого сотрудника система дает ответ на самые важные вопросы о производительности труда и эффективности использования рабочего времени: \n' +
            '- Сколько времени рабочие фактически тратят на производство работ в течение смены?\n' +
            '- Сколько времени они простаивают (разговаривают, отдыхают, ожидают)?\n' +
            '- Сколько времени уходит на их перемещения?\n' +
            '- Что мешает им быть более продуктивными?\n' +
            '\n' +
            'Используя полученные данные для мотивации рабочих и изменений рабочих процессов, компании получают возможность добиться наивысшей производительности труда за счет радикального увеличения времени полезной работы с сохранением безопасной рабочей среды.\n' +
            '\n' +
            'SOLUT работает в любых отраслях, где ручной труд является основой производства: строительство, техническое обслуживание оборудования, промышленных объектов, обслуживание недвижимости, ритейл и складское хозяйство.\n\n'
    },
    sphere: {
        heading: 'SOLUT В ОТРАСЛЯХ',
        sphere1: {
            title: 'Девелопмент и строительство',
            description: 'SOLUT позволяет ПОДРЯДЧИКАМ уменьшить расходы на труд и сократить сроки строительства за счет увеличения времени эффективной работы людей во время смены на 30% и более, увеличивая маржинальность проектов. Также при УЧАСТИИ В ТЕНДЕРАХ подрядчики получают безусловное конкурентное преимущество, так как по сравнению с конкурентами используют меньшее количество человеко-дней в оценках стоимости.  ЗАСТРОЙЩИКИ получают возможность контроля за эффективностью труда подрядчиков, оптимизируя численность рабочих и сроки проектов, что позволяет существенно снизить стоимость строительства и инвестиций и увеличить прибыльность.'
        },
        sphere2: {
            title: 'Нефтегазовая промышленность',
            description: 'Учитывая, что в нефтегазовой отрасли (в частности EPC-компании) имеют более высокие трудозатраты, чем типичные строительные подрядчики, последствия неэффективного труда катастрофичны и приводят к многомиллиардным убыткам по всей отрасли. SOLUT обеспечивает трехзначную рентабельность инвестиций для подрядчиков EPC, повышая эффективность труда при сварке, электромеханических работах, земляных работах вручную и любых других трудоемких операциях.',
        },
        sphere3: {
            title: 'Энергетика',
            description: 'Энергетика – один из критически важных секторов экономики, в котором на каждой компании лежит ответственность за непрерывность производства энергии. Сегодня SOLUT помогает энергетическим компаниям сохранять своевременность и обеспечивать идеальное состояние инфраструктуры и оборудования с использованием меньшего числа рабочих. Мы следим за тем, чтобы техническое обслуживание и капитальный ремонт выполнялись в строгом соответствии с технологией, контролирующей правильность действий персонала.' ,
        } ,
        sphere4: {
            title: 'Розничная торговля и складское хозяйство',
            description: 'Простои мерчендайзеров и складских работников оказывают существенное влияние на операционные расходы, создавая значительные финансовые потери для ритейлеров. SOLUT преодолевает эти вызовы путем мониторинга эффективного времени работы сотрудников. Благодаря нашему решению, компании могут существенно повысить эффективность своих операций и увеличить свою конкурентоспособность на рынке.',
        }
    },
    howItsWork: {
        heading: 'КАК РАБОТАЕТ SOLUT ?',
        description: '',
        description1: 'Каждый рабочий получает умные часы в начале смены на пункте выдаче / проходной',
        description2: 'После смены каждого сотрудника автоматически генерируются отчеты, содержащие информацию о времени полезной работы, времени простоя и перемещений, а также детальная фотография рабочего дня каждого сотрудника',
        description3: 'Ежедневная аналитика, обратная связь и методология мотивации обеспечивают рост производительности труда'
    },
    solveProblem: {
        heading: 'КАКИЕ ПРОБЛЕМЫ МЫ РЕШАЕМ?',
        description1: 'Длительные простои в течение рабочих смен, несоблюдение графиков перерывов',
        description2: 'Выявление причин простоя',
        description3: 'Нарушение технических процессов (последовательности действий)'
    },
    whatWeDo: {
        heading: 'ЧТО МЫ ДЕЛАЕМ?',
        description1: 'Повышаем производительность труда за счет снижения доли простоев и как следствие уменьшение стоимости часов полезной работы',
        description2: 'Выявляем причины простоя и решаем организационные проблемы',
        description3: 'Внедряем практику справедливой оплаты труда работников по их производительности и улучшаем  лояльность трудолюбивых и профессиональных сотрудников'
    },
    otherSystems: {
        heading: 'ПОЧЕМУ ДРУГИЕ ПОДХОДЫ \n НЕ ПРИНОСЯТ ОЖИДАЕМЫХ РЕЗУЛЬТАТОВ?',
        description: 'SOLUT – самая эффективная и надежная система мониторинга производительности труда на сегодняшний день. В отличие от других технологий и практик, мы проводим точные измерения в любых условиях, для любой отрасли и без дополнительной инфраструктуры или участия человека',
        system1: {
            title: 'Методы НАБЛЮДЕНИЯ',
            description: 'требуют участия человека и не всегда надежны (человеческий фактор)'
        },
        system2: {
            title: 'СИСТЕМЫ ВИДЕОНАБЛЮДЕНИЯ,',
            description: 'связанные с конкретным местоположением, требуют постоянного обслуживания и обширной инфраструктуры'
        },
        system3: {
            title: 'ОТСЛЕЖИВАНИЕ МЕСТОПОЛОЖЕНИЯ',
            description: 'позволяет узнать, где находятся сотрудники, но не обеспечивает измерение производительности и требует значительных затрат на инфраструктуру'
        }
    },
    realization: {
        heading: 'ВНЕДРЕНИЕ',
        description: '',
        step1_1: 'Свяжитесь с нами для обсуждения проекта',
        step1_2: 'и заключения контракта',
        step2_1: 'Через 2 недели наши специалисты',
        step2_2: 'приедут к вам и мы',
        step2_3: 'запустим систему',
        step3_1: 'Получайте больше прибыли',
        step3_2: 'и опережайте конкурентов,',
        step3_3: 'и стройте лучший коллектив',
    },
    charts: {
        heading: 'ЧТО ВЫ ПОЛУЧАЕТЕ?',
        chart1: {
            title: 'Фотография рабочего дня',
            description: 'Каждый работник и бригадир получают детальный обзор своей рабочей смены - каждый интервал работы, перемещений или простоя расписан поминутно в течении всей смены. Такие отчеты являются исчерпывающим инструментом для анализа рабочих ритмов, выявления временных потерь и быстрого улучшения рабочих процессов',
        },
        chart2: {
            title: 'Графики сравнения',
            description: 'Глубокая аналитика для динамичного мониторинга – SOLUT предоставляет отчеты о динамике производительности групп, графики индивидуальной производительности, а также распределение показателей в нужном разрезе',
        },
        chart3: {
            title: '',
            description: '',
        }
    },
    payback: {
        headingCost: 'СТОИМОСТЬ',
        headingPayback: 'ВОЗВРАТ ИНВЕСТИЦИЙ',
        point1: {
            number: '250',
            description: 'ОКУПАЕМОСТЬ \n ИНВЕСТИЦИЙ',
        },
        point2: {
            number: '30',
            description: 'ПОВЫШЕНИЕ \n ПРОИЗВОДИТЕЛЬНОСТИ',
        },
        point3: {
            number: '23',
            description: 'Годовая стоимость* лицензии базового ПО SOLUT на 1000 рабочих (НДС не облагается**)',
            additional: '- Минимальный объем контрактации: лицензия на 1 год на 1000 рабочих\n' +
                '- При контрактации более чем на 5000 рабочих предусмотрена скидочная программа'
        },
        point4: {
            number: '5',
            description: 'Стоимость работ по внедрению на одной площадке (НДС сверху)'
        },
        point5: {
            number: '22',
            description: 'Стоимость аппаратного обеспечения для внедрения на 1000 рабочих на одной площадке (НДС сверху)'
        },
        ps1: '*не является офертой',
        ps2: '**НДС не облагается на основании пп. 26, п. 2, ст. 149 НК РФ – программа для ЭВМ «Система мониторинга физического труда SOLUT» зарегистрирована в Едином реестре российских программ для ЭВМ (Реестровая запись №11165 от 21.07.2021)',
    },
    map: {
        heading: 'МЫ РАБОТАЕМ \n ПО ВСЕМУ МИРУ',
        dotDescription1: '',
        dotDescription2: '',
        dotDescription3: '',
        point1: {
            number: '',
            description: '',
        }
    },
    results: {
        heading: '',
        result1: {
            title: '',
            description: ''
        },
        result2: {
            title: '',
            description: '',
        },
        result3: {
            title: '',
            description: '',
        }
    },
    contacts: {
        heading: 'СВЯЖИТЕСЬ \n С НАМИ',
        card1: {
            name: 'МАРК \n ХАНИ',
            lang: 'AR/EN/FR/RU',
            phone: '+966 11 510 3310',
            country: 'Развитие бизнеса',
            email: 'marc@solut.ai',
            contactNumber: '966115103310',
            button: 'Связаться'
        },
        card2: {
            name: '',
            lang: '',
            phone: '',
            country: '',
            email: '',
            contactNumber: '',
            button: ''
        },
        card3: {
            name: 'ПАВЕЛ \n ТАТАРИНЦЕВ',
            lang: 'EN/RU',
            phone: '+971 55 948 49 38',
            country: 'Развитие бизнеса',
            email: 'pavel@solut.ai',
            contactNumber: '971559484938',
            button: 'Связаться'
        },
        card4: {
            name: 'АЛЕКСЕЙ \n ЧЕРНЫШОВ',
            lang: '',
            phone: '+7 (902) 832-48-41',
            country: 'Ведущий менеджер \n по развитию бизнеса',
            email: 'chernyshov@solut.ru',
            contactNumber: '79028324841',
            button: 'Связаться'
        }
    },
    form: {
        heading: 'ОСТАЛИСЬ ВОПРОСЫ?',
        description: 'Заполните форму обратной связи, и наши менеджеры свяжутся с вами',
        form: {
            name: 'Имя',
            contact: 'E-mail / телефон',
            question: 'Ваш вопрос',
            button: 'Отправить'
        }
    }
}

export const gb = {
    main: {
        slogan: 'WE INCREASE LABOR PRODUCTIVITY'
    },
    aboutSolut: {
        description: 'SOLUT increases labor productivity by about 30% by answering the most fundamental questions in every labor-intensive industry.\n' +
            '\n How much time laborers actually work during the shift?\n' +
            ' How much time they are idle? \n' +
            ' How much time is spent walking?\n' +
            ' What stops them from being productive?\n' +
            '\n' +
            'And we do this for every trade and every laborer, every day\n'
    },
    sphere: {
        heading: 'SOLUT IS FOR EVERY INDUSTRY',
        sphere1: {
            title: 'Construction and property development',
            description: `Contractors get unimaginable productivity levels with SOLUT. In a typical project, 30% higher productivity saves millions on labor costs, making projects more profitable. Increased labor productivity also makes contractors more competitive in bids. \n` +
                'Property developers benefit from a reduced number of laborers in contractors’ estimates and faster project times, thus saving significant amounts due to control of contractors\' efficiency'
        },
        sphere2: {
            title: 'EPC, Oil and Gas',
            description: 'Given that Oil and Gas and EPC industries have higher labor costs than typical construction contractors, the effects of inefficient labor are drastic, leading to multi-billion losses across the industry. SOLUT provides triple-digit ROI to EPC contractors, improving labor efficiency in welding, electrical and mechanical works, manual excavation, and every other labor-intensive operation',
        },
        sphere3: {
            title: 'Energy',
            description: 'Energy is one of the most critical sectors of the world’s economy. The importance of it places a heavy toll on the responsibility of every company working in it.  Every day, SOLUT helps energy companies be on time and have perfectly maintained infrastructure and equipment with less workforce than before. In addition, we make sure that maintenance and overhauls are done according to the technology controlling the correctness of laborers\' actions' ,
        } ,
        sphere4: {
            title: 'Retail and warehousing',
            description: 'Idle times of merchandisers and warehouse workers put a severe toll on the cost of operations. By measuring the active time of workers, SOLUT significantly reduces productivity losses and makes operations faster and more efficient',
        }
    },
    howItsWork: {
        heading: 'How it works?',
        description: 'SOLUT is very easy to use in every environment, it naturally fits daily routines and doesn’t interfere with laborers’ activities, comfort, safety, and privacy',
        description1: 'Every laborer gets the smartwatch at the beginning of the shift at the punch point',
        description2: 'Reports about every laborer\'s productive, idle, and walking times are generated',
        description3: ' Our methodology and daily measurement achieve actual productivity growth'
    },
    solveProblem: {
        heading: 'What problems do we solve?',
        description1: 'Long idle times during the shifts',
        description2: 'Identification of idle time reasons',
        description3: 'Violation of technical processes (sequence of actions)'
    },
    whatWeDo: {
        heading: 'What we do?',
        description1: 'Increase labor productivity by reducing the cost of productive hours and time losses',
        description2: 'Identify the reasons of idle time and resolve operational issues',
        description3: 'Implement fair pay practices for laborers and improve their wellbeing  '
    },
    otherSystems: {
        heading: 'Why other technologies \n do not deliver acceptable results?',
        description: 'SOLUT is the most efficient and reliable labor productivity monitoring system to date. Unlike other technologies and practices, we deliver precise measurements in any environment, for any trade, and without additional infrastructure or human involvement',
        system1: {
            title: 'Supervision',
            description: 'involves human factors and not sustainable'
        },
        system2: {
            title: 'Video recognition',
            description: 'linked to a specific location, requires a lot of maintenance and infrastructure'
        },
        system3: {
            title: 'Location tracking ',
            description: 'shows where the people are but doesn’t measure productivity, requires extensive infrastructure'
        }
    },
    realization: {
        heading: 'IMPLEMENTATION',
        description: 'Implementing SOLUT is effortless',
        step1_1: 'Contact us and sign a contract',
        step1_2: '',
        step2_1: 'We provide everything you need to run within 2 weeks',
        step2_2: '',
        step3_1: 'Get your profits in 3-6 months',
        step3_2: '',
        step3_3: '',

    },
    charts: {
        heading: 'What are you getting?',
        chart1: {
            title: 'Snapshot of the day ',
            description: 'Individual results and snapshot of the day - laborers and chargehands get the measurement of their results for the day, and can see every interval they worked, walked, or were idle giving an ultimate tool to understad work patterns, identify time losses, and quickly improve',
        },
        chart2: {
            title: 'Group comparison graph',
            description: 'Analytics for detailed dynamics - our analytics provides reports about groups dynamics, individual performance charts over period, results distribution by performance groups',
        },
        chart3: {
            title: 'Chart 3',
            description: 'Groups averages and rankings - simple pie charts is a powerful tool to rank groups, teams, divisions, and subcontractors by average productivity. For example, selecting subcontractors by ranking reduce costs and increase overall performance',
        }
    },
    payback: {
        heading: 'Return on investment',
        point1: {
            number: '250',
            description: 'return on investment',
        },
        point2: {
            number: '30',
            description: 'Productivity\nincrease',
        },
    },
    map: {
        heading: 'Worldwide \n presence',
        dotDescription1: '',
        dotDescription2: '',
        dotDescription3: '',
        point1: {
            number: '',
            description: '',
        }
    },
    results: {
        heading: 'Results \nof our clients',
        result1: {
            title: 'Клиент 1',
            description: 'описание'
        },
        result2: {
            title: 'Клиент 2',
            description: 'описание',
        },
        result3: {
            title: 'Клиент 3',
            description: 'описание',
        }
    },
    contacts: {
        heading: 'Contact us',
        card1: {
            name: 'mark\n Hani',
            lang: 'AR/EN/FR/RU',
            phone: '+966 11 510 3310',
            country: 'Business Development',
            email: 'marc@solut.ai',
            contactNumber: '966115103310',
            button: 'Contact'
        },
        card2: {
            name: 'Andrey\nChernykh',
            lang: 'EN/RU',
            phone: '+7 965 428-93-01',
            country: 'Business Development',
            email: 'cherand@solut.ru',
            contactNumber: '79654289301',
            button: 'Contact'
        },
        card3: {
            name: 'Pavel\nTatarintsev',
            lang: 'EN/RU',
            phone: '+971 55 948 49 38',
            country: 'Business Development',
            email: 'pavel@solut.ai',
            contactNumber: '971559484938',
            button: 'Contact'
        }
    },
    form: {
        heading: 'Have any questions?',
        description: 'Fill out the feedback form and our \nmanagers will contact you',
        form: {
            name: 'Name',
            contact: 'E-mail / or phone',
            question: 'Your question',
            button: 'Send'
        }
    }
}
